import React, {useEffect, useState} from 'react';

type Props = Readonly<{
  children: React.ReactNode,
}>;

export default function ClientOnlyContent(props: Props): JSX.Element | null {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  if (shouldRender) {
    return <>{props.children}</>;
  }

  return null;
}