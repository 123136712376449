import type {IconProps as FeatherIconProps} from 'react-feather';

import {AppIconProps} from './AppIconProps';
import useContentPalette from '../theme/useContentPalette';

export default function useFeatherIconProps(
  props: AppIconProps,
): FeatherIconProps {
  const {color, isDisabled, ...otherProps} = props;
  const palette = useContentPalette(color);
  const contentColor = isDisabled ? palette.disabled : palette.default;

  return {
    ...otherProps,
    color: contentColor,
    stroke: contentColor,
  };
}
