export type Dimensions = Readonly<{
  ratio: number,
  width: number
  height: number,
}>;

export default function getScaledDimensions(img: HTMLImageElement, maxWidth: number, maxHeight: number): Dimensions {
  const scaled = {
    height: img.height,
    ratio: img.width / img.height,
    width: img.width,
  };
  if (scaled.width > maxWidth) {
    scaled.width = maxWidth;
    scaled.height = scaled.width / scaled.ratio;
  }
  if (scaled.height > maxHeight) {
    scaled.height = maxHeight;
    scaled.width = scaled.height * scaled.ratio;
  }
  return scaled;
}