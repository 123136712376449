import getScaledDimensions from './getScaledDimensions';
import getMouseLocation from '../../browser/getMouseLocation';
import getTouchLocation from '../../browser/getTouchLocation';
import useSizeRef from '../../hooks/useSizeRef';
import ClientOnlyContent from '../ClientOnlyContent';

import {css} from '@emotion/react';
import React, {useEffect, useState} from 'react';

const SCROLL_SENSITIVITY = 0.0005;
const MIN_ZOOM = 0.3;
const MAX_ZOOM = 10;

export const WRAPPER_Z_INDEX = 1000;

export type Props = Readonly<{
  image: HTMLImageElement;
  canvasZIndex?: number;
  onChange?: (blob: Blob | null) => void;
  onFrameDrawn?: (ctx: CanvasRenderingContext2D) => void;
}>;

export default function PanAndZoomImageCanvas(props: Props): JSX.Element {
  const {canvasZIndex, image, onChange, onFrameDrawn} = props;
  const {ref, sizes} = useSizeRef();
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [cursor, setCursor] = useState<'grab' | 'grabbing'>('grab');

  useEffect(() => {
    onChange?.(blob);
  }, [blob, onChange]);

  useEffect(() => {
    if (canvasRef == null) {
      return;
    }

    let animationFrameNum: number | null = null;
    const ctx = canvasRef.getContext('2d');
    if (ctx != null && sizes != null) {
      let zoom = 1;
      const cameraOffset: {x: number; y: number} = {
        x: 0,
        y: 0,
      };

      const draw = (): void => {
        const {boundingHeight: canvasHeight, boundingWidth: canvasWidth} =
          sizes;
        ctx.resetTransform();
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        const {height, width} = getScaledDimensions(
          image,
          sizes.boundingWidth,
          sizes.boundingHeight,
        );
        ctx.translate(canvasWidth / 2, canvasHeight / 2);
        ctx.scale(zoom, zoom);
        const cameraOffsetX = cameraOffset.x;
        const cameraOffsetY = cameraOffset.y;
        ctx.translate(
          -canvasWidth / 2 + cameraOffsetX,
          -canvasHeight / 2 + cameraOffsetY,
        );

        ctx.drawImage(image, 0, 0, width, height);
        onFrameDrawn?.(ctx);
        animationFrameNum = requestAnimationFrame(draw);
      };

      const setZoom = (newZoom: number): void => {
        zoom = Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, newZoom));
      };

      const adjustZoomByDelta = (zoomDelta: number): void => {
        setZoom((zoom += zoomDelta));
      };

      let startingZoom: number | null = null;
      const adjustZoomByFactor = (factor: number): void => {
        if (startingZoom != null) {
          setZoom(startingZoom * factor);
        }
      };

      let isDragging = false;
      let dragStart = {
        x: 0,
        y: 0,
      };
      const startDragging = ({x, y}: {x: number; y: number}): void => {
        isDragging = true;
        dragStart = {
          x: x / zoom - cameraOffset.x,
          y: y / zoom - cameraOffset.y,
        };
      };
      const updateCameraOffset = ({x, y}: {x: number; y: number}): void => {
        if (isDragging) {
          cameraOffset.x = x / zoom - dragStart.x;
          cameraOffset.y = y / zoom - dragStart.y;
        }
      };

      // Mouse Events
      const onMouseDown = (e: MouseEvent) => {
        setCursor('grabbing');
        const location = getMouseLocation(e);
        if (location != null) {
          startDragging(location);
        }
      };
      const onMouseUp = () => {
        setCursor('grab');
        isDragging = false;
        dragStart = {x: 0, y: 0};
        canvasRef.toBlob(setBlob);
      };
      const onMouseMove = (e: MouseEvent) => {
        const location = getMouseLocation(e);
        if (location != null) {
          updateCameraOffset(location);
        }
      };
      canvasRef.addEventListener('mousedown', onMouseDown);
      canvasRef.addEventListener('mouseup', onMouseUp);
      canvasRef.addEventListener('mousemove', onMouseMove);
      canvasRef.addEventListener('mouseleave', onMouseUp);

      // Touch Events
      let initialPinchDistance: number | null = null;
      const onTouchStart = (e: TouchEvent) => {
        const location = getTouchLocation(e);
        if (location != null) {
          startDragging(location);
        }
      };
      const handlePinch = (touch1: Touch, touch2: Touch) => {
        const touch1Location = {x: touch1.clientX, y: touch1.clientY};
        const touch2Location = {x: touch2.clientX, y: touch2.clientY};

        // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
        const currentDistance =
          (touch1Location.x - touch2Location.x) ** 2 +
          (touch1Location.y - touch2Location.y) ** 2;

        if (initialPinchDistance == null || startingZoom == null) {
          initialPinchDistance = currentDistance;
          startingZoom = zoom;
        } else {
          adjustZoomByFactor(currentDistance / initialPinchDistance);
        }
      };

      const onTouchMove = (e: TouchEvent) => {
        e.preventDefault();
        const touch1 = e.touches.item(0);
        const touch2 = e.touches.item(1);
        if (touch1 != null && touch2 == null) {
          const location = getTouchLocation(e);
          if (location != null) {
            updateCameraOffset(location);
          }
        } else if (touch1 != null && touch2 != null) {
          isDragging = false;
          handlePinch(touch1, touch2);
        }
      };
      const onTouchEnd = () => {
        isDragging = false;
        initialPinchDistance = null;
        startingZoom = null;
        canvasRef.toBlob(setBlob);
      };
      canvasRef.addEventListener('touchstart', onTouchStart);
      canvasRef.addEventListener('touchmove', onTouchMove);
      canvasRef.addEventListener('touchend', onTouchEnd);

      // WheelEvent
      const onScroll = (e: WheelEvent) => {
        e.preventDefault();
        adjustZoomByDelta(e.deltaY * SCROLL_SENSITIVITY);
        canvasRef.toBlob(setBlob);
      };

      canvasRef.addEventListener('wheel', onScroll);

      draw();
      canvasRef.toBlob(setBlob);

      return () => {
        typeof animationFrameNum === 'number' &&
          cancelAnimationFrame(animationFrameNum);
        canvasRef.removeEventListener('mousedown', onMouseDown);
        canvasRef.removeEventListener('mouseup', onMouseUp);
        canvasRef.removeEventListener('mousemove', onMouseMove);
        canvasRef.removeEventListener('wheel', onScroll);
        canvasRef.removeEventListener('touchstart', onTouchStart);
        canvasRef.removeEventListener('touchmove', onTouchMove);
        canvasRef.removeEventListener('touchend', onTouchEnd);
      };
    }
  }, [canvasRef, image, onFrameDrawn, sizes]);

  return (
    <ClientOnlyContent>
      <div
        css={css({
          alignItems: 'center',
          cursor,
          display: 'flex',
          justifyContent: 'middle',
          height: '100%',
          width: '100%',
          zIndex: WRAPPER_Z_INDEX,
        })}
        ref={ref}
      >
        <canvas
          css={css({
            maxHeight: '100%',
            maxWidth: '100%',
            zIndex: canvasZIndex ?? 1000,
          })}
          height={sizes?.boundingHeight}
          ref={setCanvasRef}
          width={sizes?.boundingWidth}
        />
      </div>
    </ClientOnlyContent>
  );
}
