export type Coordinates = Readonly<{
  x: number,
  y: number,
}>;

export default function getMouseLocation(e: MouseEvent): Coordinates | null {
  if (e.clientX != null && e.clientY != null) {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  }
  return null;
}